import { Meta } from "@solidjs/meta";
import { type RouteDefinition, createAsync, query } from "@solidjs/router";
import type { Root } from "hast";
import { For } from "solid-js";

import { gql } from "~/__gql-generated__";
import { RadioShowType } from "~/__gql-generated__/graphql";
import Description from "~/components/Description";
import postStyles from "~/components/Post.module.scss";
import rootStyles from "~/components/Root.module.scss";
import Title from "~/components/Title";
import styles from "~/routes/grade.module.scss";
import { collectHeadingLinks } from "~/utils/collectHeadingLinks";
import { conjunctionJoin } from "~/utils/conjunctionJoin";
import { fragmentToJsx } from "~/utils/fragmentToJsx";
import { generateWidthThumbs } from "~/utils/generateThumbs";
import { client } from "~/utils/graphql";
import renderRawFragment from "~/utils/renderRawFragment";

function formatPseudoTime(time: number) {
  const hour = Math.floor(time / 10_000);
  const minute = Math.floor((time / 100) % 100);

  return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
}

const weekDayNames = [
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado",
  "Domingo",
];

const radioShowTypeLabels: Record<RadioShowType, string> = {
  [RadioShowType.FreeLive]: "Free Time",
  [RadioShowType.Live]: "Ao vivo",
  [RadioShowType.Playlist]: "Playlist",
  [RadioShowType.Podcast]: "Podcast",
};

const RADIO_SHOW_SCHEDULE = gql(`
  query RadioShowSchedule {
    radioShowSchedules(options: { sort: [{ startTime: ASC }] }) {
      id
      startTime
      endTime
      startDate
      endDate
      days
      radioShow {
        id
        slug
        title
        type
        genre
        description
        cover {
          url
        }
        djs {
          displayName
          profile {
            slug
          }
        }
      }
    }
    basePlaylist {
      id
      slug
      title
      type
      genre
      description
      cover {
        url
      }
      djs {
        displayName
        profile {
          slug
        }
      }
    }
  }
`);

const getRadioShowSchedule = query(async () => {
  "use server";

  const { data } = await client.query({ query: RADIO_SHOW_SCHEDULE });

  const allRadioShows: NonNullable<
    typeof data.basePlaylist & { htmlTitle: Root; coverSet: string }
  >[] = [];

  return {
    weekDays: await Promise.all(
      weekDayNames.map(async (weekDay, index) => ({
        name: await renderRawFragment(`<h2>${weekDay}</h2>`),
        schedules: await (async () => {
          const schedules = data.radioShowSchedules.filter(
            (schedule) => (schedule.days ?? 0) & (2 ** index),
          );

          const result: typeof schedules = [];

          if (schedules.length === 0 || schedules[0].startTime) {
            result.push({
              id: "",
              startTime: 0,
              endTime: 0,
              days: 0,
              radioShow: data.basePlaylist,
            });
            if (
              !allRadioShows.some((show) => data.basePlaylist.id === show.id)
            ) {
              allRadioShows.push({
                ...data.basePlaylist,
                htmlTitle: await renderRawFragment(
                  `
                  <h3 id="__${data.basePlaylist.slug}">
                    ${data.basePlaylist.title}
                  </h3>
                `,
                ),
                coverSet: generateWidthThumbs(data.basePlaylist.cover.url, 1),
              });
            }
          }

          for (const [index, schedule] of schedules.entries()) {
            result.push(schedule);
            if (!allRadioShows.some((show) => schedule.id === show.id)) {
              allRadioShows.push({
                ...schedule.radioShow,
                htmlTitle: await renderRawFragment(
                  `
                  <h3 id="__${schedule.radioShow.slug}">
                    ${schedule.radioShow.title}
                  </h3>
                `,
                ),
                coverSet: generateWidthThumbs(schedule.radioShow.cover.url, 1),
              });
            }

            if (
              schedule.endTime === 240_000 ||
              schedule.endTime === schedules[index + 1]?.startTime
            ) {
              continue;
            }

            result.push({
              id: "",
              startTime: schedule.endTime,
              endTime: 0,
              days: 0,
              radioShow: data.basePlaylist,
            });
            if (
              !allRadioShows.some((show) => data.basePlaylist.id === show.id)
            ) {
              allRadioShows.push({
                ...data.basePlaylist,
                htmlTitle: await renderRawFragment(
                  `<h3>${data.basePlaylist.title}</h3>`,
                ),
                coverSet: generateWidthThumbs(data.basePlaylist.cover.url, 1),
              });
            }
          }

          allRadioShows.sort((showA, showB) =>
            showA.title.localeCompare(showB.title, import.meta.env.VITE_LOCALE),
          );

          return result;
        })(),
      })),
    ),
    allRadioShowsTitle: await renderRawFragment(
      "<hr /><h2>Sobre os programas</h2>",
    ),
    allRadioShows,
    warning: await renderRawFragment(`
      <p class="callout">
        <strong>Atenção:</strong> todos os horários seguem o
        <a rel="external" href="https://time.is/Bras%C3%ADlia">
        horário de Brasília</a>.
      </p>
    `),
    heroImageSet: generateWidthThumbs(
      `${import.meta.env.VITE_SITE_HOST}/img/grade.png`,
      16 / 9,
    ),
  };
}, "radioShowSchedule");

export const route = {
  preload: () => getRadioShowSchedule(),
} satisfies RouteDefinition;

export default function RadioShowSchedule() {
  const data = createAsync(() => getRadioShowSchedule());

  return (
    <main>
      <Title>Grade de Programação</Title>
      <Description>
        Veja a nossa grade de programação e programe-se para não perder seu
        programa favorito.
      </Description>
      <Meta
        property="og:image"
        content={`${import.meta.env.VITE_SITE_HOST}/img/grade.png`}
      />
      <article class={rootStyles["styled-links"]}>
        <div class={postStyles.hero}>
          <img
            src="/img/grade.png"
            class={postStyles["hero-image"]}
            srcSet={data()?.heroImageSet}
            alt=""
          />
          <h1>Grade de Programação</h1>
          <p>
            Veja a nossa grade de programação e programe-se para não perder seu
            programa favorito.
          </p>
        </div>
        <div class={postStyles.body}>
          {collectHeadingLinks([
            ...(data()?.weekDays.map((day) => day.name) ?? []),
            data()?.allRadioShowsTitle ?? { type: "root", children: [] },
            ...(data()?.allRadioShows.map((show) => show.htmlTitle) ?? []),
          ])}
          {fragmentToJsx(data()?.warning)}
          <div class={styles.schedule}>
            <For each={data()?.weekDays ?? []}>
              {(weekDay) => (
                <div>
                  {fragmentToJsx(weekDay.name.children[0])}
                  <div class="tableWrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Início</th>
                          <th>Programa</th>
                        </tr>
                      </thead>
                      <tbody>
                        <For each={weekDay.schedules}>
                          {(schedule) => (
                            <tr>
                              <td>{formatPseudoTime(schedule.startTime)}</td>
                              <td>
                                <cite>
                                  <a href={`#__${schedule.radioShow.slug}`}>
                                    {schedule.radioShow.title}
                                  </a>
                                </cite>
                                <br />
                                <small>
                                  {[
                                    schedule.radioShow.genre,
                                    radioShowTypeLabels[
                                      schedule.radioShow.type
                                    ],
                                    schedule.radioShow.djs.length > 0
                                      ? (schedule.radioShow.djs.length === 1
                                          ? "DJ "
                                          : "DJs ") +
                                        conjunctionJoin(
                                          schedule.radioShow.djs.map(
                                            (dj) => dj.displayName,
                                          ),
                                        )
                                      : "",
                                  ]
                                    .filter(Boolean)
                                    .join(" · ")}
                                </small>
                                <br />
                              </td>
                            </tr>
                          )}
                        </For>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </For>
          </div>
          {fragmentToJsx(data()?.allRadioShowsTitle.children[0])}
          <For each={data()?.allRadioShows}>
            {(radioShow) => (
              <div class={styles.radioShow}>
                <div class={styles.image}>
                  <img src={radioShow.cover.url} alt="" />
                </div>
                {fragmentToJsx(radioShow.htmlTitle.children[0])}
                <div
                  classList={{ [styles.image]: true, [styles.alternate]: true }}
                >
                  <img src={radioShow.cover.url} alt="" />
                </div>
                <p>{radioShow.description}</p>
              </div>
            )}
          </For>
        </div>
      </article>
    </main>
  );
}
