export function tryLoadingCookieConsent() {
  const { CookieConsent } = globalThis as any;
  if (!CookieConsent) {
    requestAnimationFrame(tryLoadingCookieConsent);
    return;
  }

  void CookieConsent.run({
    guiOptions: {
      consentModal: {
        layout: "bar inline",
        position: "bottom",
      },
    },
    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
      },
      analytics: {
        autoClear: {
          cookies: [{ name: /^(?:_ga|_gid)/ }],
        },
        services: {
          ga: {
            label: "Google Analytics",
          },
        },
      },
    },
    language: {
      default: import.meta.env.VITE_LOCALE,
      translations: {
        [import.meta.env.VITE_LOCALE]: {
          consentModal: {
            title: "🍪 Louro quer biscoito? 🦜",
            description:
              'A <b>J-Hero</b> usa <i lang="en">cookies</i> para melhorar sua experiência. Ao continuar, você concorda com nosso uso de <i lang="en">cookies</i>.',
            acceptAllBtn: "Aceitar tudo",
            acceptNecessaryBtn: "Só essenciais",
            showPreferencesBtn: "Ajustes de <i>cookies</i>...",
            footer:
              '<a link href="/privacidade">Política de Privacidade</a><a link href="/contato">Contato</a>',
          },
          preferencesModal: {
            title: "Ajustes de consentimento",
            acceptAllBtn: "Aceitar tudo",
            acceptNecessaryBtn: "Só essenciais",
            savePreferencesBtn: "Salvar seleção",
            closeIconLabel: "Fechar",
            serviceCounterLabel: "serviço|serviços",
            sections: [
              {
                title: 'O que é um <i lang="en">cookie</i>?',
                description:
                  'Um <i lang="en">cookie</i> é um pequeno arquivo que <i lang="en">sites</i> põem no seu aparelho para coletar dados, armazenar preferências e, como resultado, melhorar a experiência do usuário. No entanto, há pessoas que se opõem a esse tipo de prática por questões de privacidade. Este painel permite a você definir quais serviços podem fazer uso desta tecnologia. Você pode voltar a este painel a qualquer hora clicando em “Ajustes de <i lang="en">cookies</i>...” no rodapé.',
              },
              {
                title: "Essenciais",
                description:
                  'Estes <i lang="en">cookies</i> são essenciais para o funcionamento adequado da <b>J-Hero</b> e não podem ser desativados.',
                linkedCategory: "necessary",
                cookieTable: {
                  caption: 'Tabela de <i lang="en">cookies</i>',
                  headers: {
                    name: "Idenfiticador",
                    desc: "Descrição",
                  },
                  body: [
                    {
                      name: "<code>cc_consent</code>",
                      desc: "Armazena os ajustes definidos neste painel.",
                    },
                    {
                      name: "<code>colorScheme</code>",
                      desc: 'Armazena o tema em uso do <i lang="en">site</i>.',
                    },
                    {
                      name: "<code>votedOnPoll</code>",
                      desc: "Armazena a última enquete na qual você votou.",
                    },
                  ],
                },
              },
              {
                title: "Desempenho e Analytics",
                description:
                  'Estes <i lang="en">cookies</i> coletam informações sobre como você usa o <i lang="en">site</i>.',
                linkedCategory: "analytics",
                cookieTable: {
                  caption: 'Tabela de <i lang="en">cookies</i>',
                  headers: {
                    name: "Idenfiticador",
                    desc: "Descrição",
                  },
                  body: [
                    {
                      name: "<code>_ga*</code>",
                      desc: 'Armazena e conta <i lang="en">pageviews</i>.',
                    },
                    {
                      name: "<code>_gid*</code>",
                      desc: 'Armazena e conta <i lang="en">pageviews</i>.',
                    },
                  ],
                },
              },
              {
                title: "Mais Informações",
                description:
                  'Para quaisquer perguntas sobre nossa política de <i lang="en">cookies</i> e suas escolhas, acesse nossa <a link href="/privacidade">política de privacidade</a> ou entre em <a link href="/contato">contato</a> conosco.',
              },
            ],
          },
        },
      },
    },
  });
}
