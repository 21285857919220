import { createAsync, query } from "@solidjs/router";
import { For } from "solid-js";
import { gql } from "~/__gql-generated__";

import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import styles from "~/components/SocialLinks.module.scss";
import { socialLabels } from "~/components/UserPage";
import { client } from "~/utils/graphql";
import { resolvedColorScheme } from "~/utils/resolvedColorScheme";

const OUT_SOCIAL_LINKS = gql(`
  query OurSocialLinks {
    ourSocialLinks(options: { sort: [{ weight: ASC }] }) {
      type
      link
    }
  }
`);

const getOurSocialLinks = query(async () => {
  "use server";

  const { data } = await client.query({ query: OUT_SOCIAL_LINKS });

  return data.ourSocialLinks;
}, "ourSocialLinks");

export default function SocialLinks() {
  const data = createAsync(() => getOurSocialLinks());
  const colorScheme = resolvedColorScheme();

  return (
    <ul
      classList={{ [rootStyles["clean-list"]]: true, [styles.wrapper]: true }}
    >
      <For each={data()}>
        {(link) => (
          <li>
            <SmartA href={link.link}>
              <img
                loading="lazy"
                alt={socialLabels[link.type]}
                src={`/assets/brands/${link.type.toLowerCase()}/${colorScheme()}.svg`}
              />
            </SmartA>
          </li>
        )}
      </For>
    </ul>
  );
}
