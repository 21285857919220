// @refresh reload
import * as Sentry from "@sentry/solidstart";
import { solidRouterBrowserTracingIntegration } from "@sentry/solidstart/solidrouter";
import { StartClient, mount } from "@solidjs/start/client";

import { fetchScript } from "~/utils/fetchScript";
import { isNativeApp } from "~/utils/isNativeApp";
import { tryLoadingCookieConsent } from "~/utils/tryLoadingCookieConsent";

if (!isNativeApp()) {
  void fetchScript(import.meta.env.VITE_PLAYER_URL);
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_URL,
  integrations: [solidRouterBrowserTracingIntegration()],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

tryLoadingCookieConsent();

mount(() => <StartClient />, document.querySelector("#app") ?? document.body);
