import Tooltip from "@corvu/tooltip";
import { cookieStorage, makePersisted } from "@solid-primitives/storage";
import { createEffect, createSignal } from "solid-js";

import styles from "~/components/ColorSchemeSwitcher.module.scss";
import rootStyles from "~/components/Root.module.scss";

export default function ColorSchemeSwitcher() {
  const [colorScheme, setColorScheme] = makePersisted(createSignal("system"), {
    name: "colorScheme",
    storage: cookieStorage.withOptions({
      expires: new Date(Date.now() + 3e10),
      secure: true,
    }),
    serialize: (data) => data,
    deserialize: (data) => data,
  });

  const changeColorScheme = (colorScheme: string) => {
    setColorScheme(colorScheme);
    (globalThis as any).DISQUS?.reset({ reload: true });
    const event = new CustomEvent("colorschemechange", { detail: colorScheme });
    globalThis.dispatchEvent(event);
  };

  createEffect(() => {
    changeColorScheme(colorScheme());
  });

  return (
    <section class={styles.wrapper}>
      <h2 class={rootStyles["sr-only"]}>Seletor de tema</h2>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: event delegation */}
      <ul
        onClick={(event) => {
          if (event.target instanceof HTMLInputElement) {
            changeColorScheme(event.target.value);
          }
        }}
      >
        <li>
          <input
            class={rootStyles["sr-only"]}
            checked={colorScheme() === "system"}
            type="radio"
            name="colorScheme"
            value="system"
            id="scheme-system"
          />
          <Tooltip
            placement="top"
            strategy="fixed"
            openDelay={200}
            hoverableContent={false}
            floatingOptions={{
              offset: 10,
              flip: true,
              shift: true,
            }}
          >
            <Tooltip.Trigger as="label" for="scheme-system">
              <IconTablerContrastFilled />
              <span class={rootStyles["sr-only"]}>Automático</span>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content>
                Automático
                <Tooltip.Arrow size={10} />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip>
        </li>
        <li>
          <input
            class={rootStyles["sr-only"]}
            checked={colorScheme() === "light"}
            type="radio"
            name="colorScheme"
            value="light"
            id="scheme-light"
          />
          <Tooltip
            placement="top"
            strategy="fixed"
            openDelay={200}
            hoverableContent={false}
            floatingOptions={{
              offset: 10,
              flip: true,
              shift: true,
            }}
          >
            <Tooltip.Trigger as="label" for="scheme-light">
              <IconTablerSunFilled />
              <span class={rootStyles["sr-only"]}>Tema Claro</span>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content>
                Tema Claro
                <Tooltip.Arrow size={10} />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip>
        </li>
        <li>
          <input
            class={rootStyles["sr-only"]}
            checked={colorScheme() === "dark"}
            type="radio"
            name="colorScheme"
            value="dark"
            id="scheme-dark"
          />
          <Tooltip
            placement="top"
            strategy="fixed"
            openDelay={200}
            hoverableContent={false}
            floatingOptions={{
              offset: 10,
              flip: true,
              shift: true,
            }}
          >
            <Tooltip.Trigger as="label" for="scheme-dark">
              <IconTablerMoonFilled />
              <span class={rootStyles["sr-only"]}>Tema Escuro</span>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content>
                Tema Escuro
                <Tooltip.Arrow size={10} />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip>
        </li>
      </ul>
    </section>
  );
}
