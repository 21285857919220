import ColorSchemeSwitcher from "~/components/ColorSchemeSwitcher";
import styles from "~/components/Footer.module.scss";
import PartnerList from "~/components/PartnerList";
import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import SocialLinks from "~/components/SocialLinks";
import { isCarnival } from "~/utils/isCarnival";
import { isChristmas } from "~/utils/isChristmas";
import { isEaster } from "~/utils/isEaster";
import { isSaoJoao } from "~/utils/isSaoJoao";

export default function Footer() {
  return (
    <footer
      classList={{
        [styles.footer]: true,
        [styles.carnival]: isCarnival(),
        [styles.easter]: isEaster(),
        [styles.christmas]: isChristmas(),
      }}
    >
      <div
        classList={{
          [rootStyles.container]: true,
          [styles["sao-joao"]]: isSaoJoao(),
        }}
      >
        <PartnerList />
        <hr />
        <ColorSchemeSwitcher />
        <SocialLinks />
        <p
          classList={{
            [styles.bylines]: true,
            [rootStyles["styled-links"]]: true,
          }}
        >
          Rádio J-Hero v5.0 — Do seu jeito, do seu gosto!
          <br />
          Articles and logo are © 2008–{new Date().getFullYear()} Rádio J-Hero.
          All rights reserved.
          <br />
          All dates are displayed according to{" "}
          <SmartA rel="external" href="https://time.is/Brasília">
            Brasília Time
          </SmartA>
          .<br />
          Design by <SmartA href="/kuro">Elison</SmartA>. Icons by{" "}
          <SmartA rel="external" href="https://tabler.io/icons">
            Tabler
          </SmartA>
          . Powered by{" "}
          <SmartA rel="external" href="https://netlify.com/">
            Netlify
          </SmartA>
          .<br />
          <SmartA href="/privacidade">Política de Privacidade</SmartA>
          <br />
          <button
            class={rootStyles.link}
            type="button"
            data-cc="show-preferencesModal"
          >
            Ajustes de <i lang="en">cookies</i>...
          </button>
        </p>
      </div>
    </footer>
  );
}
