import { withSentryRouterRouting } from "@sentry/solidstart/solidrouter";
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start/router";

import Root from "~/components/Root";

const SentryRouter = withSentryRouterRouting(Router);

export default function App() {
  return (
    <SentryRouter explicitLinks root={Root}>
      <FileRoutes />
    </SentryRouter>
  );
}
