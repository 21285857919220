export function webkitFocusTrick(
  element: HTMLInputElement,
  options?: FocusOptions,
) {
  const fakeInput = document.createElement("input");
  fakeInput.setAttribute("type", "text");
  fakeInput.readOnly = true;
  fakeInput.style.position = "fixed";
  fakeInput.style.opacity = "0.2";
  fakeInput.style.inlineSize = "1px";
  fakeInput.style.blockSize = "1px";
  fakeInput.style.fontSize = "16px";

  document.body.prepend(fakeInput);

  fakeInput.focus();

  setTimeout(() => {
    element.focus(options);
    fakeInput.remove();
  }, 50);
}
