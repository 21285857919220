export function isAwarenessMonth() {
  const date = new Date();

  switch (date.getMonth()) {
    case 8: {
      return "var(--color-yellow-300)";
    }
    case 9: {
      return "var(--color-pink-500)";
    }
    case 10: {
      return "var(--color-blue-500)";
    }
    default: {
      return "";
    }
  }
}
