import { createVisibilityObserver } from "@solid-primitives/intersection-observer";
import { createSignal } from "solid-js";

import Tooltip from "@corvu/tooltip";

import styles from "~/components/BackToTop.module.scss";
import FooterButtonGroup from "~/components/FooterButtonGroup";
import rootStyles from "~/components/Root.module.scss";

function BackToTop() {
  const [intersectionRef, setIntersectionRef] = createSignal<HTMLDivElement>();
  const useIntersection = createVisibilityObserver();
  const isVisible = useIntersection(intersectionRef);

  return (
    <FooterButtonGroup ref={setIntersectionRef} class={styles.wrapper}>
      <Tooltip
        placement="top"
        strategy="fixed"
        openDelay={200}
        hoverableContent={false}
        floatingOptions={{
          offset: 10,
          flip: true,
          shift: true,
        }}
      >
        <Tooltip.Trigger
          as="a"
          href="#app"
          classList={{ [styles.visible]: !isVisible() }}
          onClick={(event) => {
            event.preventDefault();
            const behavior = matchMedia("(prefers-reduced-motion: reduce)")
              .matches
              ? "auto"
              : "smooth";
            document.documentElement.scrollIntoView({ behavior });
            (document.activeElement as HTMLElement | null)?.blur();
          }}
        >
          <IconTablerChevronUp />
          <span class={rootStyles["sr-only"]}>Voltar ao Topo</span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content>
            Voltar ao Topo
            <Tooltip.Arrow size={10} />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip>
    </FooterButtonGroup>
  );
}

export default BackToTop;
